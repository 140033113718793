import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

export const CardWrapperUIBox = withStyles({
  root: {
    height: 'calc(100vh - 110px)',
    padding: '0 2em',
    position: 'relative',
    '@media (min-width:960px)': {
      height: 'calc(100vh - 155px)',
    },
    '@media (max-width:1099px)': {
      padding: 0,
    },
  },
})(Box);

export const CardWrapperScrollUIBox = withStyles((theme) => ({
  root: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100vh - 400px)',
    minHeight: 250,
    '&::-webkit-scrollbar': {
      width: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.light,
    },
  },
}))(Box);

export const CardWrapperUI = ({ children }) => (
  <CardWrapperUIBox className="MuiCard-wrapper">
    {children}
  </CardWrapperUIBox>
);

export const CardWrapperScrollUI = ({ children }) => (
  <CardWrapperScrollUIBox className="MuiCard-wrapper-scroll">
    {children}
  </CardWrapperScrollUIBox>
);
