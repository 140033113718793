import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  summary: {
    padding: '2px 0',
  },
  root: {
    margin: '1px 0 0',
    background: '#FFFFFFC0',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: '1px 0',
    },
    '& .MuiAccordionSummary-root': {
      margin: 0,
      padding: '0 1em',
      color: '#fff',
      minHeight: '2em',
      background: '#8C8C8C',
      '& .MuiIconButton-root': {
        padding: '.25em 12px',
        color: '#fff',
      },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '2em',
    },
    '& .MuiAccordionSummary-content': {
      margin: '.25em 0',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
  },
  title: {
    fontFamily: 'Muli',
    fontSize: '1em',
    fontWeight: 600,
    flex: 'auto',
  },
  listcards: {
    display: 'block',
    padding: 0,
    '& .info-item, & .user-info-item': {
      padding: '.375em .75em 0',
      borderBottom: '1px solid #f0f0f0',
    },
    '& .user-info-item': {
      paddingBottom: '.375em',
    },
  },
  card: {
    marginBottom: 16,
    borderRadius: 5,
    boxShadow: '1px 3px 7px 0 #00000020',
    backgroundColor: '#fff',
    padding: '19px 24px',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'stretch',
    alignContent: 'stretch',
    flexGrow: 1,
  },
}));
