import { getSessionStorageJson } from './ReadStorageUtils';

export const getHospitals = () => {
  const hospitalData = getSessionStorageJson('hospitalData');
  if (!hospitalData) return false;

  const { edges } = hospitalData.hospitals;
  return edges.map((edge) => edge.node);
};

export const setHospitalEnvironment = ({ uuid, dispatch, context }) => {
  const hospitals = getHospitals();

  if (!hospitals) return;
  if (!hospitals.length) return;

  const hospital = hospitals.find((h) => h.uuid === uuid)
    ? hospitals.find((h) => h.uuid === uuid)
    : hospitals[0];

  dispatch({ type: 'CHANGE_HOSPITAL', current: hospital });
  context.setPrimaryColor(hospital.mainColor);
};
